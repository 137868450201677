<template>
    <div class="container-fluid p-0 mt-3">
        <div class="row mb-2 mb-xl-3">
            <div class="col-auto d-none d-sm-block">
                <h3>Загрузка канав - на {{ year }} год - {{ getYearPercent }}%</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-start flex-wrap">
                    <div v-for="(month, monthInd) in moment.monthsShort()" :key="monthInd">
                        <div class="text-center text-uppercase mb-2 fw-bold">
                            {{ moment().month(monthInd).locale('ru').format('MMMM') }} -
                            {{ getMonthPercent(monthInd) }}%
                        </div>
                        <div
                            class="border border-2 rounded-3 me-4 mb-4"
                            style="border-color: #333 !important; padding: 15px">
                            <div v-for="row in [1, 2, 3]" :key="monthInd + '_' + row" class="d-flex">
                                <tile-component
                                    v-for="column in [3, 2, 1]"
                                    :key="monthInd + '_' + column + '_' + row"
                                    :data="getTileData(monthInd, row, column)"
                                    @on-add-section="addSectionHandler(column, row, monthInd)"
                                    @on-list-sections="listSection(column, row, monthInd)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { computed, onMounted, ref } from 'vue';
import moment from 'moment-timezone';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import TileComponent from './TileComponent.vue';

const year = ref(moment().year());
const items = ref<Array<{ id: number; dateStart: string; dateEnd: string }>>([]);

const getMonthPercent = computed(() => {
    return (month: number) => {
        let filled = 0;

        for (let r = 1; r <= 3; r++) {
            for (let c = 1; c <= 3; c++) {
                filled = filled + getTileData.value(month, r, c).percent;
            }
        }

        return parseInt(String((filled * 100) / 900));
    };
});

const getYearPercent = computed(() => {
    let filled = 0;

    for (let m = 0; m < 12; m++) {
        for (let r = 1; r <= 3; r++) {
            for (let c = 1; c <= 3; c++) {
                filled = filled + getTileData.value(m, r, c).percent;
            }
        }
    }

    return parseInt(String((filled * 100) / 10800));
});

const getTileData = computed(() => {
    return (month: any, row: any, col: any) => {
        const minDate = moment();
        minDate.set('month', month);
        minDate.set('date', 1);

        const maxDate = moment();
        maxDate.set('month', month);
        maxDate.endOf('month');

        let _items = items.value.filter(
            (i: any) =>
                i.row === row &&
                i.col === col &&
                (moment(i.dateStart).month() === month || moment(i.dateEnd).month() === month),
        );
        let isDisable = false;
        let dates = [];

        // console.log(items);

        while (minDate.isSameOrBefore(maxDate)) {
            _items = items.value.filter(
                (i: any) =>
                    i.row === row &&
                    i.col === col &&
                    minDate.isBetween(moment(i.dateStart), moment(i.dateEnd).add(1, 'day')),
            );
            isDisable = false;
            // console.log(minDate.format('M/D/YYYY'), _items, '---');
            for (let i = 0; i < _items.length; i++) {
                // console.log(_items[i]);
                // console.log(month, row, col, _items);
                // console.log(maxDate.format('MM DD'), moment(_items[i].dateStart).format('MM DD'), moment(_items[i].dateEnd).format('MM DD'), minDate.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd)));
                if (minDate.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day'))) {
                    isDisable = true;
                    break;
                } else {
                    isDisable = false;
                }
            }

            dates.push({
                date: minDate.format('M/D/YYYY'),
                isDisable: isDisable,
            });

            minDate.add(1, 'days');
        }
        // _items = items.value.filter((i: any) => i.row === row && i.col === col && minDate.isBetween(moment(i.dateStart), moment(i.dateEnd)));
        // for (let i = 0; i < _items.length; i++) {
        //     if (minDate.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd))) {
        //         isDisable = true;
        //         break;
        //     } else {
        //         isDisable = false;
        //     }
        // }

        // dates.push({
        //     date: minDate.format('M/D/YYYY'),
        //     isDisable: isDisable,
        // });

        // console.log(maxDate.format('MM DD'), dates, dates.filter(i => i.isDisable));
        let allDays = parseInt(maxDate.format('DD'));
        let days = dates.filter((i) => i.isDisable).length;
        let percent = parseInt(String((days * 100) / allDays));
        let bg = percent < 50 ? 'bg-white' : percent >= 50 && percent < 90 ? 'bg-warning' : 'bg-success';
        return {
            dates,
            month,
            row,
            col,
            days,
            allDays,
            percent,
            bg,
            items: _items,
        };
    };
});

onMounted(() => {
    api.newPlan.get().then((r) => (items.value = r.data));
});

function addSectionHandler(column: any, row: any, monthInd: any) {
    useModal('add_section_modal')
        .open({
            column: column,
            row: row,
            monthInd: monthInd,
            year: year.value,
            items: items.value,
        })
        .then((r) => {
            console.log(r);
            // @ts-ignore
            items.value.push(r.loco);
        })
        .catch((e) => {
            console.log(11111);
        });
}

function listSection(column: any, row: any, monthInd: any) {
    useModal('loading_ditches_list_modal')
        .open({
            column: column,
            row: row,
            monthInd: monthInd,
            year: year.value,
            items: items.value,
            removeHandler(id: number) {
                let ind = items.value.findIndex((i) => i.id === id);
                if (ind !== -1) {
                    items.value.splice(ind, 1);
                }
            },
        })
        .then((r) => {
            console.log('123');
        })
        .catch((e) => {
            console.log(11111);
        });
}
</script>

<style scoped lang="scss"></style>
