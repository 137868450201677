<template>
    <div class="container-fluid p-0">
        <div class="row mb-2 mb-xl-3">
            <div class="col-auto d-none d-sm-block">
                <h3>Документы</h3>
            </div>
            <div class="col-auto ms-auto text-end mt-n1 d-flex">
                <button-ui button-class="btn-primary ms-3 btn-sm" @click="handleCreateDocument"> Создать </button-ui>
            </div>
        </div>
    </div>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card border">
                    <div class="card-header">
                        <div class="card-title">Фильтр</div>
                    </div>
                    <div class="card-body pt-0 loader">
                        <form>
                            <div class="row">
                                <div class="col-12 col-lg-2">
                                    <form-group-ui label="Тип">
                                        <select-ui
                                            item-key="name"
                                            :options="[{ id: 0, name: 'Не выбрано' }, ...state.documentTypes]"
                                            placeholder="Тип"
                                            :searchable="true"
                                            :values="[state.selectedDocumentType]"
                                            @on-selected="handleSelectDocumentType" />
                                    </form-group-ui>
                                </div>
                                <div class="col-12 col-lg-2">
                                    <form-group-ui label="Подтип">
                                        <select-ui
                                            item-key="name"
                                            :options="[
                                                { id: 0, name: 'Не выбрано' },
                                                ...state.filteredDocumentSubTypes,
                                            ]"
                                            placeholder="Подтип"
                                            :searchable="true"
                                            :values="[state.selectedDocumentSubType]"
                                            @on-selected="handleSelectDocumentSubType" />
                                    </form-group-ui>
                                </div>
                                <div class="col-12 col-lg-2">
                                    <form-group-ui label="Наименование">
                                        <input-ui v-model="state.search" placeholder="Название" :searchable="true" />
                                    </form-group-ui>
                                </div>
                                <div class="col-12 col-lg-2">
                                    <form-group-ui label="Контрагент">
                                        <select-ui
                                            item-key="name"
                                            :options="state.contractors"
                                            placeholder="Контрагент"
                                            :searchable="true"
                                            :values="[state.contractorId]"
                                            @on-selected="handleSupplierSelect" />
                                    </form-group-ui>
                                </div>
                                <div class="col-12 col-lg-2">
                                    <form-group-ui label="Техника">
                                        <select-ui
                                            item-key="name"
                                            :options="filteredLocomotives"
                                            placeholder="Техника"
                                            :searchable="true"
                                            :values="[state.locomotiveId]"
                                            @on-selected="handleLocomotiveSelect" />
                                    </form-group-ui>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 670px">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="py-2 px-3"># п/п</th>
                            <th class="py-2 px-3">Тип документа</th>
                            <th class="py-2 px-3">Подтип документа</th>
                            <th class="py-2 px-3">Дата</th>
                            <th class="py-2 px-3">Наименование</th>
                            <th class="py-2 px-3">Краткое содержание</th>
                            <th class="py-2 px-3">Контрагент</th>
                            <th class="py-2 px-3">Техника</th>
                            <th class="py-2 px-3">Тэги</th>
                            <th class="py-2 px-3">Автор</th>
                            <th class="py-2 px-3">Обновлено</th>
                            <th class="py-2 px-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.documents.length">
                            <tr v-for="(document, idx) in state.documents" :key="document.id">
                                <td class="py-2 px-3">{{ idx + 1 }}</td>
                                <td class="py-2 px-3">{{ document?.subType?.documentType?.name }}</td>
                                <td class="py-2 px-3">{{ document?.subType?.name }}</td>
                                <td class="py-2 px-3">{{ formatDate(document.documentedAt) }}</td>
                                <td class="py-2 px-3">
                                    <a class="text-primary" :href="backendUrl + document.path" target="_blank">
                                        {{ document.fileName }}
                                    </a>
                                </td>
                                <td class="py-2 px-3">{{ document?.comment || '-' }}</td>
                                <td class="py-2 px-3">{{ document.contractor?.name || '-' }}</td>
                                <td class="py-2 px-3">{{ document.locomotive?.name || '-' }}</td>
                                <td class="py-2 px-3">{{ document.tags.length ? document.tags.join(', ') : '-' }}</td>
                                <td class="py-2 px-3">{{ document.author?.fullName || document.author?.login }}</td>
                                <td class="py-2 px-3">{{ formatDate(document.updatedAt, 'DD.MM.YYYY HH:mm') }}</td>
                                <td class="py-2 px-3">
                                    <dropdown-ui v-if="user.id === document.authorId">
                                        <template #button>
                                            <button class="btn btn-sm d-flex justify-content-center align-items-center">
                                                <vue-feather size="14px" type="more-vertical" />
                                            </button>
                                        </template>
                                        <template #list>
                                            <button-ui
                                                class="dropdown-item cursor-pointer"
                                                @click="handleEditDocument(document)">
                                                Редактировать
                                            </button-ui>
                                            <button-ui
                                                class="dropdown-item cursor-pointer"
                                                @click="handledeleteSummary(document.id)">
                                                Удалить
                                            </button-ui>
                                        </template>
                                    </dropdown-ui>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td class="py-2 px-3 text-center" colspan="5">Документы отсутствуют</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { reactive, onMounted, watch, computed } from 'vue';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import api from '@/api';
import { userStore } from '@/store/userStore';
import { useModal } from '@/composables/useModal';
import { IDocument, IDocumentType, IDocumentSubtype } from '@/@types/IDocument';
import { ISupplier } from '@/@types/ISupplier';
import { ILocomotive } from '@/@types/ILocomotive';
import formatDate from '@/utils/formatDate';

const backendUrl = process.env.VUE_APP_API_URL;

type stateType = {
    documents: IDocument[];
    documentTypes: IDocumentType[];
    documentSubTypes: IDocumentSubtype[];
    filteredDocumentSubTypes: IDocumentSubtype[];
    contractors: ISupplier[];
    locomotives: ILocomotive[];
    search: string;
    selectedDocumentType: number;
    selectedDocumentSubType: number;
    locomotiveId: number;
    contractorId: number;
};

const { user } = userStore();
const state: stateType = reactive({
    documents: [],
    documentTypes: [],
    documentSubTypes: [],
    filteredDocumentSubTypes: [],
    contractors: [],
    locomotives: [],
    search: '',
    selectedDocumentType: 0,
    selectedDocumentSubType: 0,
    locomotiveId: 0,
    contractorId: 0,
});

async function getDocuments(params = {}) {
    return api.document.getMany(params).then(({ data }) => {
        state.documents = data;
    });
}

async function getDocumentTypes() {
    return api.document.getTypes().then(({ data }) => {
        state.documentTypes = data;
    });
}

async function getDocumentSubTypes() {
    return api.document.getSubTypes().then(({ data }) => {
        state.documentSubTypes = data;
        state.filteredDocumentSubTypes = data;
    });
}

async function getContractors() {
    return api.supplier.get().then((r) => {
        state.contractors = r.data;
    });
}

async function getLocomotives() {
    return api.locomotives
        .getAll()
        .then((data) => {
            state.locomotives = data;
        })
        .catch(console.error);
}

const filteredLocomotives = computed(() => {
    if (!state.contractorId) {
        return [];
    }

    const contractor = state.contractors.find((c) => c.id === state.contractorId);

    if (!contractor) {
        return [];
    }

    return state.locomotives.filter(
        (locomotive) => locomotive.contractorId === Number(contractor.rollingStockOwner?.id),
    );
});

onMounted(() => {
    getDocuments();
    getDocumentTypes();
    getDocumentSubTypes();
    getContractors();
    getLocomotives();
});

function handleCreateDocument() {
    useModal('document_modal').open().then(getDocuments).catch(console.error);
}

function handleSelectDocumentType(documentType: IDocumentType) {
    if (state.selectedDocumentType === documentType.id) {
        state.selectedDocumentType = 0;
        state.filteredDocumentSubTypes = state.documentSubTypes;
    } else {
        state.selectedDocumentType = documentType.id;
        state.selectedDocumentSubType = 0;
        state.filteredDocumentSubTypes = state.documentSubTypes.filter(
            (subType) => subType.documentTypeId === documentType.id,
        );
    }
}

function handleSelectDocumentSubType(documentSubType: IDocumentSubtype) {
    state.selectedDocumentSubType = state.selectedDocumentSubType === documentSubType.id ? 0 : documentSubType.id;
}

function handleSupplierSelect(supplier: ISupplier) {
    state.locomotiveId = 0;
    state.contractorId = state.contractorId === supplier.id ? 0 : supplier.id;
}

function handleLocomotiveSelect(locomotive: ILocomotive) {
    state.locomotiveId = state.locomotiveId === locomotive.id ? 0 : locomotive.id;
}

function handleEditDocument(document: IDocument) {
    useModal('edit_document_modal')
        .open({
            document,
            documentNames: state.documents.reduce(
                (acc, d) => (d.id === document.id ? acc : [...acc, d.fileName]),
                [] as string[],
            ),
        })
        .then(getDocuments)
        .catch(console.error);
}

function handledeleteSummary(id: number) {
    useModal('confirm_modal')
        .open({
            question: 'Подтвердите удаление документа',
            ok: 'Да',
            cancel: 'Нет',
        })
        .then((r: any) => api.document.delete(id))
        .then(getDocuments);
}

watch(
    () => [
        state.search,
        state.selectedDocumentType,
        state.selectedDocumentSubType,
        state.locomotiveId,
        state.contractorId,
    ],
    () =>
        getDocuments({
            ...(!!state.selectedDocumentType && { selectedDocumentType: state.selectedDocumentType }),
            ...(!!state.selectedDocumentSubType && { selectedDocumentSubType: state.selectedDocumentSubType }),
            ...(!!state.locomotiveId && { locotomiveId: state.locomotiveId }),
            ...(!!state.contractorId && { contractorId: state.contractorId }),
            ...(!!state.search && { search: state.search }),
        }),
);
</script>

<style scoped lang="scss"></style>
