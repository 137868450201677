<template>
    <modal-ui :id="id" size="modal-lg" title="Редактировать выбор поставщика">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12 col-md-10">
                    <form-group-ui invalid-feedback="Выберите поставщика" label="Поставщик" required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isSupplierIdError"
                            item-key="name"
                            :options="suppliers"
                            :searchable="true"
                            :values="[state.supplierId]"
                            @on-updated="handleSupplierSelect" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2 d-flex align-items-center">
                    <label class="form-check">
                        <input v-model="state.isVatIncludedInPrice" class="form-check-input" type="checkbox" />
                        <span class="form-check-label">НДС</span>
                    </label>
                </div>
            </div>
            <ul class="nav nav-tabs">
                <li
                    v-for="tab in TAB_COLLECTION"
                    :key="tab"
                    class="nav-item"
                    @click="state.selectedTab = tab">
                    <a class="nav-link text-black" :class="{ active: state.selectedTab === tab }" href="#">
                        {{ tab }}
                    </a>
                </li>
            </ul>
            <div v-if="state.selectedTab === TAB.PURCHASE" class="row mb-4">
                <div class="col-12 row">
                    <div class="col-3 border text-center fw-bold">Материал</div>
                    <div class="col-2 border text-center fw-bold">Общее кол-во</div>
                    <div class="col-2 border text-center fw-bold">Кол-во для закупа</div>
                    <div class="col-2 border text-center fw-bold">Цена</div>
                    <div class="col-3 border text-center fw-bold">Сумма</div>
                </div>
                <div
                    v-for="(materialWithPrice, idx) in state.materialsWithPrice"
                    :key="materialWithPrice.protocolMaterialId"
                    class="col-12 row">
                    <div class="col-3 border d-flex align-items-center">
                        {{ materialWithPrice.material?.materialName }}
                    </div>
                    <div class="col-2 border d-flex align-items-center justify-content-end">
                        {{ materialWithPrice?.material.count }}&nbsp;
                        {{ materialWithPrice?.material?.unit }}
                    </div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Укажите количество" required>
                            <input-ui
                                v-model="materialWithPrice.quantity"
                                class="mt-2 text-end"
                                :is-invalid="state.visibleErrors && isQuantityError(materialWithPrice)"
                                :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="12"
                                placeholder="Количество"
                                @input="handleUnitPriceChange(idx)" />
                        </form-group-ui>
                    </div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Укажите цену" required>
                            <input-ui
                                v-model="materialWithPrice.unitPrice"
                                class="mt-2 text-end"
                                :is-invalid="state.visibleErrors && isUnitPriceError(materialWithPrice)"
                                :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="12"
                                placeholder="Цена"
                                @input="handleUnitPriceChange(idx)" />
                        </form-group-ui>
                    </div>
                    <div class="col-3 border d-flex align-items-center justify-content-end">
                        <b>{{ formatMoney(computeMaterialTotal(materialWithPrice)) }}</b>
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-5 border d-flex align-items-center">Доставка</div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Укажите количество" required>
                            <input-ui
                                v-model="state.shippingQuantity"
                                class="mt-2 text-end"
                                :is-invalid="state.visibleErrors && isShippingQuantityError"
                                :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="12"
                                placeholder="Количество"
                                @input="handleShippingQuantityChange" />
                        </form-group-ui>
                    </div>
                    <div class="col-2 border">
                        <form-group-ui invalid-feedback="Ошибочная цена" required>
                            <input-ui
                                v-model="state.shippingPrice"
                                class="mt-2 text-end"
                                :is-invalid="state.visibleErrors && isShippingPriceError"
                                :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="12"
                                placeholder="Цена"
                                @input="handleShippingPriceChange" />
                        </form-group-ui>
                    </div>
                    <div class="col-3 border d-flex align-items-center justify-content-end">
                        <b>{{ formatMoney(shippingTotal) }}</b>
                    </div>
                </div>
                <div class="col-12">
                    <div class="fw-bold text-end py-1">
                        Итого: {{ formatMoney(total) }}&nbsp;{{ state.currency }} (без НДС:
                        {{ formatMoney(!state.isVatIncludedInPrice ? total : total / 1.12) }} {{ state.currency }})
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <div class="row">
                        <div class="col-4 border text-center fw-bold">
                            <form-group-ui label="Валюта" required>
                                <select-ui
                                    item-key="name"
                                    :options="CURRENCY_COLLECTION"
                                    :values="[state.currency]"
                                    @on-updated="handleCurrencySelect" />
                            </form-group-ui>
                        </div>
                        <div class="col-4 border text-center fw-bold">
                            <form-group-ui invalid-feedback="Ошибочный курс" label="Курс" required>
                                <input-ui
                                    v-model="state.exchangeRate"
                                    class="mt-2 text-end"
                                    :disabled="state.currency === CURRENCY.KZ_TENGE"
                                    :is-invalid="state.visibleErrors && isExchangeRateError"
                                    :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
                                    maxlength="12"
                                    placeholder="Курс"
                                    @input="handleExchangeRateChange" />
                            </form-group-ui>
                        </div>
                        <div class="col-4 border text-center fw-bold">
                            Cумма в нац. валюте: {{ formatMoney(total * Number(state.exchangeRate)) }}&nbsp; (без НДС:
                            {{
                                formatMoney(
                                    !state.isVatIncludedInPrice
                                        ? total * Number(state.exchangeRate)
                                        : (total / 1.12) * Number(state.exchangeRate),
                                )
                            }})
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-2">
                    <button class="btn btn-primary" type="button" @click="handleChangeNextBtn">
                        Далее
                    </button>
                </div>
            </div>
            <div v-if="state.selectedTab === TAB.PAYMENT" class="row mb-4">
                <div class="col-12 mt-2">
                    <h6>Условия оплаты</h6>
                    <div class="row">
                        <div class="col-2 border text-center fw-bold">Транш</div>
                        <div class="col-5 border text-center fw-bold">Процент</div>
                        <div class="col-5 border text-center fw-bold">Сумма</div>
                    </div>
                    <div v-for="tranch in state.paymentTranches" :key="tranch.number" class="row">
                        <div class="col-2 border">
                            {{ tranch.number + 1 }}
                        </div>
                        <div class="col-5 border">
                            <form-group-ui invalid-feedback="Сумма процентов траншей должно быть 100" required>
                                <input-ui
                                    v-model="tranch.percent"
                                    class="mt-2 text-end"
                                    :is-invalid="state.visibleErrors && isTranchesError"
                                    placeholder="Инфо"
                                    type="number" />
                            </form-group-ui>
                        </div>
                        <div class="col-5 border d-flex align-items-center justify-content-end">
                            {{ formatMoney(computedTranchTotal(+tranch.percent)) }}
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-2">
                    <form-group-ui invalid-feedback="Условия поставки" label="Условия поставки">
                        <text-area-ui v-model="state.description" placeholder="Условия поставки" />
                    </form-group-ui>
                </div>
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary"> Сохранить </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from 'vue';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import { ISupplier } from '@/@types/ISupplier';
import formatMoney from '@/utils/formatMoney';
import { CURRENCY } from '@depo/constants/purchase';

type protocolSupplierMaterialType = {
    sum: string;
    total: number;
    quantity: string;
    unitPrice: string;
    groupId: number;
    protocolMaterialId: number;
    material: {
        materialName: string;
        count: string;
        unit: string;
        requiredMaterialIds: Array<{ count: string; id: number }>;
    };
};

type stateType = {
    id: number;
    supplierId: number | string;
    isVatIncludedInPrice: boolean;
    description?: string;
    shippingQuantity: string;
    shippingPrice: string;
    selectedTab: string;
    currency: string;
    exchangeRate: string;
    materialsWithPrice: protocolSupplierMaterialType[];
    paymentTranches: {
        number: number;
        percent: number;
    }[];
    visibleErrors: boolean;
    loader: boolean;
};

const TAB = {
    PURCHASE: 'Закуп',
    PAYMENT: 'Оплата',
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);

const suppliers = ref<ISupplier[]>([]);

const state: stateType = reactive({
    id: 0,
    supplierId: '',
    isVatIncludedInPrice: false,
    description: '',
    materialsWithPrice: [],
    currency: CURRENCY.KZ_TENGE,
    exchangeRate: '1',
    selectedTab: TAB.PURCHASE,
    shippingQuantity: '',
    shippingPrice: '',
    paymentTranches: [
        {
            percent: 0,
            number: 0,
        },
        {
            percent: 0,
            number: 1,
        },
        {
            percent: 0,
            number: 2,
        },
    ],
    visibleErrors: false,
    loader: false,
});

const CURRENCY_COLLECTION = Object.values(CURRENCY).map((currency) => ({ id: currency, name: currency }));
const TAB_COLLECTION = Object.values(TAB);

const isSupplierIdError = computed(() => !state.supplierId);
const isUnitPriceError = computed(() => (material: any) => {
    return (+material.quantity ? +material.unitPrice <= 0 : +material.unitPrice < 0) || !isFinite(+material.unitPrice);
});
const isQuantityError = computed(() => (material: protocolSupplierMaterialType) => {
    if (material.material.requiredMaterialIds.length !== 1) {
        return +material.quantity !== +material.material.count;
    }

    return +material.quantity < 0 || +material.quantity > +material.material.count;
});

const materialsTotal = computed(() =>
    state.materialsWithPrice.reduce((acc, mp) => {
        if (isUnitPriceError.value(mp) || !+mp.quantity) {
            return acc;
        }

        return acc + +mp.quantity * +mp.unitPrice;
    }, 0),
);

const total = computed(() => materialsTotal.value + shippingTotal.value);

const isExchangeRateError = computed(() => +state.exchangeRate <= 0);

const isShippingPriceError = computed(() => {
    return +state.shippingPrice < 0 || (!materialsTotal.value && !!shippingTotal.value);
});
const isShippingQuantityError = computed(() => +state.shippingQuantity < 0);

const isTranchesError = computed(() => state.paymentTranches.reduce((acc, t) => acc + +t.percent, 0) !== 100);
const shippingTotal = computed(() => +state.shippingQuantity * +state.shippingPrice);

const computeMaterialTotal = computed(() => (material: any) => {
    return !isUnitPriceError.value(material) && material.quantity ? +material.quantity * +material.unitPrice : 0;
});

const computedTranchTotal = computed(
    () => (percent: number) => (total.value && percent ? total.value * (percent / 100) : 0),
);

onMounted(() => {
    if (Object.values(modal.data?.protocolSupplier || {}).length) {
        state.supplierId = modal.data.protocolSupplier.supplierId;
        state.description = modal.data.protocolSupplier.description || '';
        state.isVatIncludedInPrice = modal.data.protocolSupplier.isVatIncludedInPrice;
        state.materialsWithPrice = modal.data.protocolSupplier.materialsWithConditions.map((m: any) => ({
            ...m,
        }));
        state.shippingPrice = modal.data.protocolSupplier?.shippingPrice || 0;
        state.shippingQuantity = modal.data.protocolSupplier?.shippingQuantity || 0;
        state.exchangeRate = modal.data.protocolSupplier?.exchangeRate || '1';
        state.currency = modal.data.protocolSupplier?.currency || CURRENCY.KZ_TENGE;
        state.paymentTranches = modal.data.protocolSupplier?.paymentTranches.length
            ? modal.data.protocolSupplier?.paymentTranches.map((pt: any) => ({
                number: pt.number,
                percent: pt.tranchPercent,
            }))
            : [0, 1, 2].map((number) => ({ number, percent: 0 }));
    }
    if (modal.data?.suppliers?.length) {
        suppliers.value = modal.data?.suppliers;
    }
});

function handleChangeNextBtn() {
    const isErrorsExist = state.materialsWithPrice.some((rm) => isUnitPriceError.value(rm) || isQuantityError.value(rm)) ||
        isSupplierIdError.value ||
        isShippingPriceError.value ||
        isShippingQuantityError.value ||
        isExchangeRateError.value;

    if (isErrorsExist) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.selectedTab = TAB.PAYMENT;
}

function handleSupplierSelect(m: any) {
    state.supplierId = m.id;
}

function handleCurrencySelect(m: { id: string }) {
    state.currency = m.id;
    if (m.id === CURRENCY.KZ_TENGE) {
        state.exchangeRate = '1';
    }
}

function handleUnitPriceChange(idx: number) {
    state.materialsWithPrice[idx].unitPrice = state.materialsWithPrice[idx].unitPrice.replaceAll(',', '.');
}

function handleShippingQuantityChange() {
    state.shippingQuantity = state.shippingQuantity.replaceAll(',', '.');
}

function handleShippingPriceChange() {
    state.shippingPrice = state.shippingPrice.replaceAll(',', '.');
}

function handleExchangeRateChange() {
    state.exchangeRate = state.exchangeRate.replaceAll(',', '.');
}

const isErrorsExist = computed(() => {
    return (
        state.materialsWithPrice.some((rm) => isUnitPriceError.value(rm) || isQuantityError.value(rm)) ||
        isSupplierIdError.value ||
        isTranchesError.value ||
        isShippingPriceError.value ||
        isShippingQuantityError.value ||
        isExchangeRateError.value
    );
});

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.supplierSelection
        .updateSupplier({
            protocolSupplierId: modal.data.protocolSupplier.id,
            supplierId: state.supplierId,
            isVatIncludedInPrice: state.isVatIncludedInPrice,
            description: state.description,
            materialsWithPrice: state.materialsWithPrice,
            paymentTranches: state.paymentTranches,
            shippingQuantity: state.shippingQuantity,
            shippingPrice: state.shippingPrice,
            exchangeRate: state.exchangeRate,
            currency: state.currency,
        })
        .then((r) => {
            modal.ok(r.data);
        });
}
</script>

<style scoped lang="scss"></style>
