<template>
    <div class="row mb-2">
        <div class="col-auto ms-auto text-end mt-1 d-flex">
            <button-ui
                v-if="user.isMaster || user.isSuperAdmin || user.isTechnologist || user.isHeadOfDepot"
                button-class="btn-primary ms-3 btn-sm"
                @click="createSummaryHandler">
                Создать
            </button-ui>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-md-4 col-xl-3">
            <form-group-ui label="Сотрудник" required>
                <select-ui
                    item-key="name"
                    :options="state.employeesInSectionJobSummary"
                    placeholder="Сотрудник"
                    :searchable="true"
                    :values="[state.employeeId]"
                    @on-selected="employeeSelectHandler" />
            </form-group-ui>
        </div>
        <div class="col-6 col-md-4 col-xl-3">
            <form-group-ui label="Период">
                <date-picker v-model="state.filterDate" mode="date" range :timezone="'Asia/Ashgabat'">
                    <template #default="{ inputValue, inputEvents }">
                        <div class="d-flex justify-center items-center">
                            <input
                                class="form-control"
                                placeholder="С"
                                :value="inputValue.start"
                                v-on="inputEvents.start" />
                            <svg
                                class="mx-2"
                                fill="none"
                                stroke="currentColor"
                                style="height: 32px; width: 56px"
                                viewBox="0 0 24 24">
                                <path
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2" />
                            </svg>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    placeholder="По"
                                    :value="inputValue.end"
                                    v-on="inputEvents.end" />
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    @click="resetFilterDatesHandler">
                                    X
                                </button>
                            </div>
                        </div>
                    </template>
                </date-picker>
            </form-group-ui>
        </div>
    </div>
    <div class="row">
        <div v-if="!state.isLoading">
            <div v-if="!state.rows.length">
                <div class="card py-2 px-3 lead">Сводки не найдены</div>
            </div>
            <div v-else class="overflow-hidden">
                <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 670px">
                    <table class="table table-bordered">
                        <thead class="sticky-top bg-white">
                            <tr class="position-relative border-0">
                                <th class="py-2 px-3">Дата</th>
                                <th class="py-2 px-3">Локомотив&nbsp;(секция)</th>
                                <th class="py-2 px-3">Цех&nbsp;(подразделение)</th>
                                <th class="py-2 px-3">Конструктив</th>
                                <th class="py-2 px-3">ФИО</th>
                                <th class="py-2 px-3">Чел&nbsp;/&nbsp;час</th>
                                <th class="py-2 px-3">Примечание</th>
                                <th class="py-2 px-3">Автор</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(summary, summaryIdx) in state.rows" :key="summary.id">
                                <tr
                                    v-for="(summaryEmployee, summaryEmployeeIdx) in summary.summaryDetailsPerEmployee"
                                    :key="summaryEmployee.id">
                                    <td
                                        v-if="!summaryEmployeeIdx"
                                        class="py-2 px-3"
                                        :rowspan="summary.summaryDetailsPerEmployee.length">
                                        {{ formatDate(summary.happenedAt) }}
                                    </td>
                                    <td
                                        v-if="!summaryEmployeeIdx"
                                        class="py-2 px-3"
                                        :rowspan="summary.summaryDetailsPerEmployee.length">
                                        {{
                                            summary?.sectionJob
                                                ? `№${summary.sectionJob.section.loco.number}
                                                ${summary.sectionJob.section.loco.locoModel.name}
                                                (${summary.sectionJob.section.number})`
                                                : ''
                                        }}
                                    </td>
                                    <td
                                        v-if="!summaryEmployeeIdx"
                                        class="py-2 px-3"
                                        :rowspan="summary.summaryDetailsPerEmployee.length">
                                        {{
                                            summary?.department
                                                ? `${summary.department.name}
                                                (${summary.department.subdivision.name})`
                                                : ''
                                        }}
                                    </td>
                                    <td class="py-2 px-3">
                                        {{ summaryEmployee.subgroup.nm }}.&nbsp;
                                        {{ summaryEmployee.subgroup.group_name }}
                                    </td>
                                    <td
                                        class="py-2 px-3"
                                        :class="{
                                            'bg-warning':
                                                state.employeeId && state.employeeId === summaryEmployee.employeeId,
                                        }">
                                        {{ summaryEmployee.employee.name }}&nbsp; ({{ summaryEmployee.employee.post }})
                                    </td>
                                    <td class="py-2 px-3 text-end">
                                        {{ summaryEmployee.hoursWorked }}
                                    </td>
                                    <td class="py-2 px-3">{{ summaryEmployee.comment }}</td>
                                    <td
                                        v-if="!summaryEmployeeIdx"
                                        class="py-2 px-3"
                                        :rowspan="summary.summaryDetailsPerEmployee.length">
                                        ФИО: {{ summary?.author?.name ?? '-' }}&nbsp; <br />логин:&nbsp;{{
                                            summary?.author?.login
                                        }}
                                    </td>
                                    <td
                                        v-if="!summaryEmployeeIdx"
                                        class="py-2 px-3"
                                        :rowspan="summary.summaryDetailsPerEmployee.length">
                                        <dropdown-ui v-if="user.id === summary.userId">
                                            <template #button>
                                                <button
                                                    class="btn btn-sm d-flex justify-content-center align-items-center">
                                                    <vue-feather size="14px" type="more-vertical" />
                                                </button>
                                            </template>
                                            <template #list>
                                                <button-ui
                                                    class="dropdown-item cursor-pointer"
                                                    @click="editSummaryHandler(summary, summaryIdx)">
                                                    Редактировать
                                                </button-ui>
                                                <button-ui
                                                    class="dropdown-item cursor-pointer"
                                                    @click="deleteSummaryHandler(summaryIdx)">
                                                    Удалить
                                                </button-ui>
                                            </template>
                                        </dropdown-ui>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="m-3 d-flex justify-content-end">
                        <v-pagination
                            v-model="state.page"
                            active-color="#DCEDFF"
                            class="pagination"
                            :pages="state.pagesCount"
                            :range-size="1" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center py-4 mt-4">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Загрузка...</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { reactive, onMounted, watch } from 'vue';
import VPagination from '@hennge/vue3-pagination';
import { DatePicker } from 'v-calendar';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import api from '@/api';
import { userStore } from '@/store/userStore';
import { ISummary } from '@/@types/ISummary';
import formatDate from '@/utils/formatDate';
import { useModal } from '@/composables/useModal';
import { IEmployeeTariff } from '@/@types/IEmployeeTariff';

type stateType = {
    rows: ISummary[];
    page: number;
    pagesCount: number;
    isLoading: boolean;
    employeesInSectionJobSummary: Array<IEmployeeTariff['employee']>;
    employeeId: number;
    filterDate: {
        start: string;
        end: string;
    };
};

const { user } = userStore();
const state: stateType = reactive({
    rows: [],
    page: 1,
    pagesCount: 0,
    isLoading: false,
    employeesInSectionJobSummary: [],
    employeeId: 0,
    filterDate: {
        start: '',
        end: '',
    },
});

async function getSummaries(params = {}, isEmployeesIncludes = true) {
    state.isLoading = true;
    return Promise.all([
        api.sectionJobSummary.get(params).then(({ data }) => {
            state.rows = data.rows;
            state.pagesCount = Math.ceil(data.count / 50);
            state.isLoading = false;
        }),
        ...(isEmployeesIncludes
            ? [
                api.employees.getEmployeesInSectionJobSummary().then((res) => {
                    state.employeesInSectionJobSummary = res.data;
                }),
            ]
            : []),
    ]);
}

onMounted(getSummaries);

function createSummaryHandler() {
    useModal('section_job_summary_modal')
        .open({
            type: 'add',
        })
        .then(() => getSummaries({ page: state.page }));
}

function editSummaryHandler(summary: ISummary, idx: number) {
    useModal('section_job_summary_modal')
        .open({
            type: 'edit',
            summary,
        })
        .then(() => getSummaries({ page: state.page }));
}

function deleteSummaryHandler(idx: number) {
    useModal('confirm_modal')
        .open({
            question: 'Подтвердите удаление сводки',
            ok: 'Да',
            cancel: 'Нет',
        })
        .then((r: any) => api.sectionJobSummary.delete(state.rows[idx].id))
        .then(() => getSummaries({ page: 1 }));
}

function employeeSelectHandler(_employee: IEmployeeTariff['employee']) {
    if (state.employeeId === _employee.id) {
        state.employeeId = 0;
        return;
    }
    state.employeeId = _employee.id;
}

function resetFilterDatesHandler() {
    state.filterDate = {
        start: '',
        end: '',
    };
}

watch(
    () => [state.employeeId, state.filterDate, state.page],
    (v) => {
        if (!state.employeeId && !state.page && !state.filterDate.end && !state.filterDate.start) {
            return;
        }
        getSummaries(
            {
                ...(!!state.employeeId && { employeeId: state.employeeId }),
                ...(typeof state.page === 'number' && { page: state.page }),
                ...(!!state.filterDate.start &&
                    !!state.filterDate.end && {
                    filterStartsAt: state.filterDate.start,
                    filterEndsAt: state.filterDate.end,
                }),
            },
            false,
        );
    },
);
</script>

<style scoped lang="scss"></style>
