<template>
    <div class="row">
        <div class="col-12 col-md-2">
            <form-group-ui class="me-2" label="Юр лицо">
                <select v-model="state.supplierId" class="form-select">
                    <option :value="0">Не выбрано</option>
                    <option :value="-1">
                        {{ state.depo?.info || 'Депо' }}
                    </option>
                    <option v-for="supplier in state.suppliers" :key="supplier.id" :value="supplier.id">
                        {{ supplier.name }}
                    </option>
                </select>
            </form-group-ui>
        </div>
        <div class="col-12 col-md-2">
            <form-group-ui v-if="state.organizations.length" class="me-2" label="Основание">
                <select v-model="state.chosenOrganizationId" class="form-select">
                    <option :value="0">Все</option>
                    <option v-for="organization in state.organizations" :key="organization.id" :value="organization.id">
                        {{ organization.name }}
                    </option>
                </select>
            </form-group-ui>
        </div>
        <div class="col-12 col-md-2">
            <form-group-ui class="me-2" label="Подразделение">
                <select v-model="state.chosenPlot" class="form-select">
                    <option value="">Все</option>
                    <option v-for="plot in departments" :key="plot.id" :value="plot.realName">
                        {{ plot.name }}
                    </option>
                </select>
            </form-group-ui>
        </div>
        <div class="col-12 col-md-2">
            <form-group-ui class="me-2" label="Автор">
                <select v-model="state.chosenAuthorId" class="form-select">
                    <option value="">Все</option>
                    <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.login }}
                    </option>
                </select>
            </form-group-ui>
        </div>
        <div class="col-12 col-md-2">
            <form-group-ui class="me-2" label="Месяц">
                <select v-model="state.month" class="form-select">
                    <option disabled value="">Месяц</option>
                    <option v-for="(month, monthIdx) in months" :key="month" :value="monthIdx">
                        {{ month }}
                    </option>
                </select>
            </form-group-ui>
        </div>
        <div class="col-12 col-md-2">
            <form-group-ui label="Год">
                <input-ui
                    v-model="state.year"
                    :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
                    maxlength="4"
                    placeholder="Год" />
            </form-group-ui>
        </div>
    </div>
    <div>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 650px">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="p-2">ФИО</th>
                            <th class="p-2">Должность</th>
                            <template v-for="date in daysInMonth" :key="date">
                                <th class="p-2" :class="{ 'bg-light': isWeekend(date) }">
                                    {{ date }}
                                </th>
                            </template>
                            <th class="p-2">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.reports.length">
                            <tr v-for="employee in state.reports" :key="employee.id">
                                <td class="p-2">
                                    {{ employee.name }}
                                </td>
                                <td class="p-2">
                                    {{ employee.post }}
                                </td>
                                <template
                                    v-for="({ details, hours, status }, date) in employee.workedHoursInMonth"
                                    :key="date">
                                    <td class="p-2" :class="{ 'bg-light': isWeekend(date + 1) }">
                                        <popover-ui
                                            v-if="!!hours || !!status"
                                            class="border-0 m-0"
                                            :content="
                                                Object.keys(details).reduce(
                                                    (acc, key) => acc + `${key}: ${details[key]}<br />`,
                                                    '',
                                                )
                                            ">
                                            {{ status ? status : hours }}
                                        </popover-ui>
                                    </td>
                                </template>
                                <td class="p-2">
                                    {{ employee.totalByMonth }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="py-5 text-center" :colspan="3 + daysInMonth.length">Отсутствуют данные</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <button-ui button-class="btn-outline-success ms-3 sticky-bottom" @click="handlePdfDownload">
                    Экспорт в Excel
                </button-ui>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { watch, reactive, computed, onMounted, ref } from 'vue';
import moment from 'moment-timezone';
import api from '@/api';
import { IUnknownObject } from '@/@types/common';
import PopoverUi from '@/components/ui/PopoverUi.vue';
import { ISupplier } from '@/@types/ISupplier';
import { IDepartment } from '@/@types/ISubdivision';
import { IUser } from '@/@types/IUser';

moment.locale('ru');

type reportType = {
    id: number;
    name: string;
    post: string;
    orgName: string;
    workedHoursInMonth: { hours: number; details: IUnknownObject; status: string }[];
    totalByMonth: number;
};

type organizationType = {
    id: number;
    name: string;
};

type reportCardStateType = {
    date: string | Date;
    reports: reportType[];
    organizations: organizationType[];
    suppliers: Array<ISupplier>;
    chosenOrganizationId: number;
    supplierId?: number;
    month: number;
    year: number;
    chosenPlot: string;
    chosenAuthorId: number | string;
    depo: {
        name?: string;
        id?: number;
        info?: string;
    };
};

const state: reportCardStateType = reactive({
    date: new Date(),
    organizations: [],
    suppliers: [],
    reports: [],
    supplierId: -1,
    chosenAuthorId: '',
    chosenOrganizationId: 0,
    month: moment().month(),
    year: moment().year(),
    chosenPlot: '',
    depo: {},
});

function handlePdfDownload() {
    api.employees
        .getReportCardPdf({
            month: state.month,
            year: state.year,
            chosenOrganizationId: state.chosenOrganizationId,
            ...(!!state.chosenPlot && {
                chosenPlot: state.chosenPlot,
            }),
        })
        .then((response) => {
            const headerContentDisp = response.headers['content-disposition'];
            const filename = headerContentDisp?.split('filename=')[1].replace(/["']/g, '');
            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers['content-type'] || 'text/xlsx',
                }),
            );
            const link = document.createElement('a');
            link.setAttribute('download', filename || 'someFile.xlsx');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            link.remove();
        });
}

function getReportCard() {
    return api.employees
        .getReportCard({
            month: state.month,
            year: state.year,
            chosenOrganizationId: state.chosenOrganizationId,
            ...(!!state.chosenPlot && {
                chosenPlot: state.chosenPlot,
            }),
        })
        .then(({ data }) => {
            state.reports = data.reportCard;
            state.depo = data.depo;
            users.value = data.authors;
        });
}

const months = computed(() => moment.months());
const departments = ref<IDepartment[]>([]);
const users = ref<IUser[]>([]);

const daysInMonth = computed(() => {
    return Array.from(
        { length: moment().set({ month: state.month, year: state.year }).daysInMonth() },
        (x, i) => i + 1,
    );
});

const isWeekend = computed(() => (date: number) => {
    return [6, 7].includes(moment().set({ month: state.month, year: state.year, date }).isoWeekday());
});

onMounted(() => {
    getReportCard();
    api.department.get().then((r: any) => {
        departments.value = r.data;
    });
    api.organizations.get().then(({ data }) => {
        if (!data.length) {
            return;
        }
        state.organizations = data;
    });
    api.employees.getEmployeeSupplier().then((res) => {
        if (!res.data.length) {
            return;
        }
        state.suppliers = res.data;
    });
});

watch(
    () => [
        state.month,
        state.year,
        state.chosenOrganizationId,
        state.supplierId,
        state.chosenPlot,
        state.chosenAuthorId,
    ],
    (v: any) => {
        if (typeof state.month !== 'number' || !state.year) {
            return;
        }
        api.employees
            .getReportCard({
                month: state.month,
                year: state.year,
                chosenOrganizationId: state.chosenOrganizationId,
                ...(!!state.chosenPlot && {
                    chosenPlot: state.chosenPlot,
                }),
                ...(!!state.chosenAuthorId && {
                    chosenAuthorId: state.chosenAuthorId,
                }),
                ...(!!state.supplierId && {
                    supplierId: state.supplierId,
                }),
            })
            .then(({ data }) => {
                state.reports = data.reportCard;
                state.depo = data.depo;
                users.value = data.authors;
            });
    },
);
</script>

<style scoped lang="scss">
.delete-employee .delete-employee-btn {
    opacity: 0;
}

.delete-employee:hover .delete-employee-btn {
    opacity: 1;
}
</style>
