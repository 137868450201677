<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="overflow-hidden">
        <div class="bg-white position-relative overflow-auto mw-100 pb-6" style="max-height: 650px">
            <table class="table table-bordered">
                <thead class="sticky-top bg-white">
                    <tr class="position-relative border-0">
                        <th class="p-2">Дата&nbsp;тендера</th>
                        <th
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            <popover-ui
                                class="text-decoration-underline"
                                :content="
                                    'ОИД:\xa0' +
                                    project.oidPercent +
                                    '%,\xa0квал.требования: ' +
                                    (project.qualificationRequirements || '-')
                                ">
                                {{ formatDate(project.tenderStartsAt) }}
                            </popover-ui>
                        </th>
                        <th v-else class="p-2">Данных нет</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="position-relative border-0">
                        <th class="p-2">Дата&nbsp;заезда</th>
                        <th
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatDate(project.entersAt) }}
                        </th>
                        <th v-else class="p-2">Данных нет</th>
                    </tr>
                    <tr>
                        <th class="p-2">Клиент</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            <popover-ui class="text-decoration-underline" :content="getContractTerms(project)">
                                {{ project.contractor.name?.replaceAll(' ', '\xa0').replaceAll('-', '&#8209;') }}
                            </popover-ui>
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">ПС</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ project.locomotiveModel.name }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Гос.номер</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ project.locomotive?.number || '' }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Вид&nbsp;ремонта</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ project.jobCategory.name?.replaceAll('-', '&#8209;') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Стоимость&nbsp;без&nbsp;НДС</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatMoney(project.totalWithoutVAT, 0, '.', '\xa0') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">НДС</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatMoney(project.vat, 0, '.', '\xa0') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Стоимость&nbsp;с&nbsp;НДС</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatMoney(project.total, 0, '.', '\xa0') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Себестоимость&nbsp;(без&nbsp;НДС)</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            <popover-ui class="text-decoration-underline" :content="getConstructivePrices(project)">
                                {{ formatMoney(project.costPrice, 0, '.', '\xa0') }}
                            </popover-ui>
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Маржа&nbsp;(тенге)</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatMoney(project.margin, 0, '.', '\xa0') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Рентабельность&nbsp;(%)</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ formatMoney(project.profitability, 0, '.', '\xa0') }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Примечание</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2">
                            {{ project.comment }}
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                    <tr>
                        <th class="p-2">Согласование</th>
                        <td
                            v-for="project in state.projectRegisters"
                            v-if="state.projectRegisters.length"
                            :key="project.id"
                            class="p-2"
                            style="white-space: pre-line">
                            <span v-for="approval in project.approvals" :key="approval.id">
                                <b>
                                    {{ approval.user.login }}
                                </b>
                                :{{
                                    ` ${approval?.status}, ${formatDate(approval?.updatedAt)} \n\
                            Комментарий: ${approval?.comment}\n`
                                }}
                            </span>
                            <br />
                            <span
                                v-if="
                                    !project.approvals.some(
                                        (app) => app.userId === user.id && app.status === APPROVAL_STATUS.APPROVE,
                                    ) &&
                                    [ROLE.ADMIN.VALUE, ROLE.CEO.VALUE, ROLE.SUPER_ADMIN.VALUE].includes(user.roleId)
                                "
                                class="d-flex flex-column align-items-start">
                                <button
                                    class="btn bg-primary bg-opacity-50 mb-2"
                                    :data-project-id="project.id"
                                    :data-status="APPROVAL_STATUS.APPROVE"
                                    @click="handleApprove">
                                    Согласовать
                                </button>
                                <button
                                    class="btn btn-outline-danger bg-opacity-50"
                                    :data-project-id="project.id"
                                    :data-status="APPROVAL_STATUS.REJECTED"
                                    @click="handleApprove">
                                    &nbsp;&nbsp;Отклонить&nbsp;&nbsp;
                                </button>
                            </span>
                        </td>
                        <td v-else class="p-2">Данных нет</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { reactive, onMounted } from 'vue';
import { APPROVAL_STATUS, APPROVAL_STATUS_COLLECTION } from '@depo/constants/project-register';
import { ROLE } from '@depo/constants/user';
import api from '@/api';
import PopoverUi from '@/components/ui/PopoverUi.vue';
import { IProjectRegister } from '@/@types/IProjectRegister';
import formatDate from '@/utils/formatDate';
import { userStore } from '@/store/userStore';
import { useModal } from '@/composables/useModal';
import formatMoney from '@/utils/formatMoney';

type stateType = {
    projectRegisters: Array<IProjectRegister>;
};

const state: stateType = reactive({
    projectRegisters: [],
});

const { user } = userStore();

function getProjectRegisters() {
    return api.projectRegister.getMany().then(({ data }) => {
        state.projectRegisters = data;
    });
}

function getContractTerms(project: IProjectRegister) {
    return `
    Аванс:\xa0${project.advancePercent}<br />
    Оплата\xa0после\xa0сдачи:\xa0${project.daysToGetPaidAfterActOfWorkPerformedSubmit}\xa0дн.<br />
    Срок\xa0ремонта:\xa0${project.repairPeriodDays}\xa0дн.<br />
    Полная\xa0оплата:\xa0${project.fullPayment}<br />
    Пеня\xa0за\xa0простой:\xa0${project.demuragePenalty}`;
}

function getConstructivePrices(project: IProjectRegister) {
    return project.constructivePrices.reduce((text, { total, constructive: { nm, group_name } }) => {
        if (!total) return text;

        return text + `${nm}. ${group_name}:\xa0${formatMoney(total, 0, '.', '\xa0')}<br />`;
    }, '');
}

function handleApprove(e: Event) {
    const currentTarget = e.currentTarget as HTMLButtonElement;
    const { status, projectId } = currentTarget.dataset;
    if (!status || !APPROVAL_STATUS_COLLECTION.includes(status) || !projectId) {
        return;
    }

    useModal('text_modal')
        .open({
            title: 'Согласование: ' + status,
            btn: 'Сохранить',
            value: '',
            label: 'Комментарий',
        })
        .then((r: any) => {
            return api.projectRegister.approve(+projectId, {
                status,
                comment: r.value,
            });
        })
        .then(getProjectRegisters)
        .catch(console.error);
}

onMounted(getProjectRegisters);
</script>

<style scoped lang="scss">
tr:hover td,
tr:hover th {
    background-color: #ccc;
}
</style>
