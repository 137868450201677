<template>
    <div class="row mb-2 mb-xl-3">
        <div class="col-auto ms-auto text-end mt-n1">
            <button-ui v-if="isTable === 0" button-class="btn-secondary me-3" @click="isTable = 1"> Вид 2 </button-ui>
            <button-ui v-if="isTable === 1" button-class="btn-secondary me-3" @click="isTable = 0"> Вид 1 </button-ui>
            <button-ui
                v-if="user.isAdmin || user.isCentrsklad || user.isTechnologist || user.isSales || user.isSuperAdmin"
                button-class="btn-primary"
                @click="addRate">
                Добавить
            </button-ui>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <table v-if="!isTable" class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="p-2">ID</th>
                            <th class="p-2">Модель</th>
                            <th class="p-2">Вид работы</th>
                            <th class="p-2" colspan="2">Продолжительность работы в днях</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item) in list" :key="item.id">
                            <td class="p-2">
                                {{ item.id }}
                            </td>
                            <td class="p-2">
                                {{ item.modelName }}
                            </td>
                            <td class="p-2">
                                {{ item.jobName }}
                            </td>
                            <td class="p-2">
                                {{ item.days }}
                            </td>
                            <td class="p-2 text-end">
                                <button-ui
                                    v-if="
                                        user.isAdmin ||
                                        user.isCentrsklad ||
                                        user.isTechnologist ||
                                        user.isSales ||
                                        user.isSuperAdmin
                                    "
                                    button-class="btn-primary btn-sm"
                                    @click="editItem(item)">
                                    Редактировать
                                </button-ui>
                                <button-ui
                                    v-if="
                                        user.isAdmin ||
                                        user.isCentrsklad ||
                                        user.isTechnologist ||
                                        user.isSales ||
                                        user.isSuperAdmin
                                    "
                                    button-class="btn-danger btn-sm ms-3"
                                    @click="deleteItem(item)">
                                    Удалить
                                </button-ui>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table v-if="isTable" class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="p-2">Модель</th>
                            <th v-for="r in getRates" class="p-2">
                                {{ r.jobName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, itemInd) in getTable" :key="item.id">
                            <td class="p-2">
                                {{ item.modelName }}
                            </td>
                            <td v-for="r in getRates" class="p-2">
                                {{ getCurrentRate(item, r) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { computed, onMounted, ref } from 'vue';
import api from '@/api';
import { useModal } from '@/composables/useModal';
import { userStore } from '@/store/userStore';

const list = ref([]);
const isTable = ref(0);
const { user } = userStore();

const getCurrentRate = computed(() => {
    return (item: any, r: any) => {
        let f = item.rates.find((i: any) => i.jobId === r.jobId);
        if (f) {
            return f.days;
        }

        return '-';
    };
});

const getTable = computed(() => {
    return list.value.reduce((r: any, c: any) => {
        let ind = r.findIndex((i: any) => i.modelId === c.modelId);

        if (ind === -1) {
            r.push({
                ...c,
                ...{
                    rates: [c],
                },
            });
        } else {
            r[ind].rates.push(c);
        }

        return r;
    }, []);
});

const getRates = computed(() => {
    return list.value.reduce((r: any, c: any) => {
        let ind = r.findIndex((i: any) => i.jobId === c.jobId);

        if (ind === -1) {
            r.push({
                jobId: c.jobId,
                jobName: c.jobName,
                days: c.days,
            });
        }

        return r;
    }, []);
});

onMounted(() => {
    api.rate.get().then((r) => (list.value = r.data));
});

function addRate() {
    useModal('item_equipment_modal')
        .open({
            type: 'add',
            list: list,
        })
        .then((r: any) => {
            list.value.unshift(r.item);
        });
}

function editItem(item: any) {
    useModal('item_equipment_modal')
        .open({
            type: 'edit',
            item: item,
            list: list,
        })
        .then((r: any) => {
            let ind = list.value.findIndex((i: any) => i.id === r.item.id);

            if (ind !== -1) {
                list.value[ind].modelId = r.item.modelId;
                list.value[ind].jobId = r.item.jobId;
                list.value[ind].days = r.item.days;
            }
        });
}

function deleteItem(item: any) {
    api.rate.remove(item.id).then((r: any) => {
        let ind = list.value.findIndex((i: any) => i.id === item.id);

        if (ind !== -1) {
            list.value.splice(ind, 1);
        }
    });
}
</script>

<style scoped lang="scss"></style>
