<template>
    <modal-ui :id="id" size="modal-sm" title="Загрузка документа">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите файл" label="Выберите файл" required>
                        <input-ui
                            ref="file"
                            accept="application/pdf"
                            :is-invalid="isFileError && state.visibleErrors"
                            type="file"
                            @change="selectFile($event)" />
                    </form-group-ui>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui button-class="btn-primary"> Сохранить </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from 'vue';
import { useModal } from '@/composables/useModal';
import api from '@/api';

export default defineComponent({
    name: 'DocumentModal',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);

        const state = reactive({
            id: 0,
            file: null,
            visibleErrors: false,
            loader: false,
        });

        const isFileError = computed(() => !state.file);

        function selectFile(e: any) {
            const file = e.target.files[0];
            state.file = file;
        }

        function send() {
            if (isFileError.value) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            let formData = new FormData();

            // @ts-ignore
            formData.append('document', state.file);
            formData.append('date', new Date().toISOString());

            api.document.create(formData).then((r) => {
                modal.ok({ isSuccess: true });
            });
        }

        function ok(loco: any) {
            modal.ok({
                loco: loco,
            });
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            modal,
            cancel,
            close,
            state,
            send,
            isFileError,
            selectFile,
        };
    },
});
</script>

<style scoped lang="scss"></style>
