<template>
    <div class="row mb-2 mb-xl-3">
        <div class="col-auto ms-auto text-end mt-1 d-flex">
            <button-ui button-class="btn-primary ms-3 btn-sm" @click="handleCreateProjectRegister">
                Создать
            </button-ui>
        </div>
    </div>
    <div class="overflow-hidden">
        <div class="bg-white position-relative overflow-auto mw-100 pb-6" style="max-height: 650px">
            <table class="table table-bordered">
                <thead class="sticky-top bg-white">
                    <tr class="position-relative border-0">
                        <th class="p-2">Дата&nbsp;тендера</th>
                        <th class="p-2">Проект&nbsp;(лот)</th>
                        <th class="p-2">Стоимость&nbsp;без&nbsp;НДС</th>
                        <th class="p-2">НДС</th>
                        <th class="p-2">Стоимость&nbsp;c&nbsp;НДС</th>
                        <th class="p-2">Условия&nbsp;участия</th>
                        <th class="p-2">Условия&nbsp;контракта</th>
                        <th class="p-2">Себестоимость</th>
                        <th class="p-2">Маржа</th>
                        <th class="p-2">Рентабельность&nbsp;(%)</th>
                        <th class="p-2">Примечание</th>
                        <th class="p-2">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="state.projectRegisters.length">
                        <tr v-for="projectRegister in state.projectRegisters" :key="projectRegister.id">
                            <td class="p-2">
                                {{ formatDate(projectRegister.tenderStartsAt) }}
                            </td>
                            <td class="p-2">
                                <span v-if="projectRegister.portal">
                                    Портал:&nbsp;{{ projectRegister.portal }}
                                    <br />
                                </span>
                                Клиент:&nbsp;{{
                                    projectRegister.contractor.name
                                        ?.replaceAll(' ', '\xa0')
                                        .replaceAll('-', '&#8209;')
                                }}<br />
                                ПС:&nbsp;{{ projectRegister.locomotiveModel.name }}
                                <span v-if="projectRegister.locomotiveId">
                                    Гос.номер:&nbsp;{{ projectRegister.locomotive?.number }}
                                </span>
                                <br />
                                Вид&nbsp;работ:&nbsp;{{
                                    projectRegister.jobCategory.name?.replaceAll('-', '&#8209;')
                                }}<br />
                                <span v-if="projectRegister.entersAt">
                                    Дата&nbsp;заезда:&nbsp;{{ formatDate(projectRegister.entersAt) }}
                                </span>
                                <br />
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(+projectRegister.totalWithoutVAT, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(projectRegister.vat, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(projectRegister.total, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2">
                                <span>ОИД:&nbsp;{{ projectRegister.oidPercent }}%</span><br />
                                <span>
                                    Тендерное&nbsp;обеспечение:&nbsp;{{
                                        projectRegister.tenderSecurityPercent
                                    }}%
                                </span>
                                <br />
                                <span v-if="projectRegister.qualificationRequirements?.length">
                                    Квал.требования:&nbsp;{{ projectRegister.qualificationRequirements }}
                                </span>
                            </td>
                            <td class="p-2">
                                Аванс:&nbsp;{{ projectRegister.advancePercent }}% <br />
                                Оплата&nbsp;после&nbsp;сдачи&nbsp;АВР:&nbsp;{{
                                    projectRegister.daysToGetPaidAfterActOfWorkPerformedSubmit
                                }}&nbsp;дн.<br />
                                Срок&nbsp;ремонта:&nbsp;{{ projectRegister.repairPeriodDays }}&nbsp;дн.<br />
                                Полная&nbsp;оплата:&nbsp;{{ projectRegister.fullPayment }}<br />
                                Пеня&nbsp;за&nbsp;простой:&nbsp;{{ projectRegister.demuragePenalty }}%<br />
                                Оплата&nbsp;сцерхцикла:&nbsp;{{ projectRegister.isOvercyclePaid ? 'Да' : 'Нет'
                                }}<br />
                                Установка&nbsp;доп.&nbsp;оборудования::&nbsp;{{
                                    projectRegister.isAdditionalEquipmentInstalled ? 'Да' : 'Нет'
                                }}<br />
                                Спец&nbsp;условия:&nbsp;{{ projectRegister.isSpecialConditions ? 'Да' : 'Нет'
                                }}<br />
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(projectRegister.costPrice, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(projectRegister.margin, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2 text-end">
                                {{ formatMoney(projectRegister.profitability, 0, '.', '\xa0') }}
                            </td>
                            <td class="p-2">
                                {{ projectRegister?.comment }}
                            </td>
                            <td class="p-2">
                                <dropdown-ui>
                                    <template #button>
                                        <button
                                            class="btn btn-sm d-flex justify-content-center align-items-center">
                                            <vue-feather size="14px" type="more-vertical" />
                                        </button>
                                    </template>
                                    <template #list>
                                        <button-ui
                                            class="dropdown-item cursor-pointer"
                                            @click="handleEditProjectRegister(projectRegister)">
                                            Редактировать
                                        </button-ui>
                                        <button-ui
                                            class="dropdown-item cursor-pointer"
                                            @click="handleCalculateConstructivePrice(projectRegister)">
                                            Себестоимость
                                        </button-ui>
                                        <button-ui
                                            class="dropdown-item cursor-pointer"
                                            @click="handleOpenDatasheetModal(projectRegister)">
                                            Техническое состояние
                                        </button-ui>
                                        <button-ui class="dropdown-item cursor-pointer" disabled>
                                            Отправить в архив
                                        </button-ui>
                                    </template>
                                </dropdown-ui>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td class="p-2 text-center" colspan="12">Данных нет</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" async setup>
import { reactive, onMounted } from 'vue';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import { IProjectRegister } from '@/@types/IProjectRegister';
import formatDate from '@/utils/formatDate';
import formatMoney from '@/utils/formatMoney';

type stateType = {
    projectRegisters: Array<IProjectRegister>;
};

const state: stateType = reactive({
    projectRegisters: [],
});

function getProjectRegisters() {
    return api.projectRegister.getMany().then(({ data }) => {
        state.projectRegisters = data;
    });
}

onMounted(getProjectRegisters);

function handleCreateProjectRegister() {
    useModal('project_register_modal')
        .open({ type: 'add' })
        .then(getProjectRegisters)
        .catch((e) => console.log(e));
}

function handleEditProjectRegister(projectRegister: IProjectRegister) {
    useModal('project_register_modal')
        .open({ type: 'edit', projectRegister })
        .then(getProjectRegisters)
        .catch((e) => {
            console.log(e);
        });
}

function handleCalculateConstructivePrice(projectRegister: IProjectRegister) {
    useModal('project_register_price_modal')
        .open({ projectRegister })
        .then(getProjectRegisters)
        .catch((e) => {
            console.log(e);
        });
}

function handleOpenDatasheetModal(projectRegister: IProjectRegister) {
    useModal('project_register_datasheet_modal')
        .open({ projectRegister })
        .then(getProjectRegisters)
        .catch((e) => {
            console.log(e);
        });
}
</script>

<style scoped lang="scss"></style>
