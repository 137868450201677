import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-179f0b8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["border border-2 rounded-3 mx-2 my-2 d-flex justify-content-center align-items-center cursor-pointer position-relative tile", _ctx.data.bg]),
    style: {"border-color":"#333 !important","width":"60px","height":"100px"},
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.listHandler && _ctx.listHandler(...args)), ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data.percent) + "% ", 1),
    (_ctx.user.isAdmin || _ctx.user.isTechnologist || _ctx.user.isSales || _ctx.user.isSuperAdmin)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tile__add fw-bold",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addHandler && _ctx.addHandler(...args)), ["stop"]))
        }, " + "))
      : _createCommentVNode("", true)
  ], 2))
}