<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите наименование ТМЦ" label="Наименование ТМЦ" required>
                        <select-ui :is-invalid="state.visibleErrors && isMaterialIdError"
                            :options="materials" placeholder="Наименование ТМЦ" :searchable="true"
                            :values="[state.materialId]"
                            @on-selected="(value: any) => onMaterialSelected(value)" />
                    </form-group-ui>
                </div>
                <div class="col-12 d-flex align-items-center">
                            <div>Ед.изм:</div>
                            <div v-if="state.materialId">
                                {{ handleGetMaterialUnitDimension }}
                            </div>
                            <div v-else>-</div>
                        </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите дату" label="Дата" required>
                        <date-picker v-model="state.filledAt" as readonly :timezone="'Asia/Almaty'" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isFilledAtError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите количество" label="Количество" required>
                        <input-ui v-model="state.remainsQuantity"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                            :is-invalid="state.visibleErrors && isCountError"
                            @input="onCountEnter"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts" async setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { DatePicker } from 'v-calendar';
import api from "@/api";
import { IMaterial } from "@/@types/IMaterial";

const props = defineProps({
    id: Number,
});

const modal = useModal(String(props.id));

const materials = ref<IMaterial[]>([]);

const state = reactive({
    title: '',
    id: 0,
    remainsQuantity: '',
    filledAt: '',
    materialId: 0,
    comment: '',
    visibleErrors: false,
    loader: false,
});

const isMaterialIdError = computed(() => !state.materialId);
const isCountError = computed(() => +state.remainsQuantity <= 0);
const isFilledAtError = computed(() => !state.filledAt);

const errors = computed(() => {
    return isMaterialIdError.value || isCountError.value || isFilledAtError.value;
});

onMounted(() => {
    state.title = 'Редактирование';

    api.materials.getOld().then(r => materials.value = r.data);

    if (Object.keys(modal.data?.materialStockTaking || {}).length) {
        state.remainsQuantity = modal.data.materialStockTaking.remainsQuantity;
        state.filledAt = modal.data.materialStockTaking.filledAt;
        state.materialId = modal.data.materialStockTaking.materialId;
        state.comment = modal.data.materialStockTaking.comment;
    }
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;
    if (!modal.data?.materialStockTaking?.id) {
        return;
    }
    api.materialStockTaking.update(+modal.data.materialStockTaking.id, {
        remainsQuantity: state.remainsQuantity,
        materialId: state.materialId,
        filledAt: state.filledAt,
        comment: state.comment,
    }).then((r: any) => {
        if (r.data) {
            modal.ok(r.data);
        }
    }).finally(() => state.loader = false);
}

function onMaterialSelected(_m: any) {
    state.materialId = _m.id;
}

function onCountEnter() {
    state.remainsQuantity = state.remainsQuantity.replaceAll(',', '.');
}

const handleGetMaterialUnitDimension = computed(() => {
    const material = materials.value.find((m) => m.id === +state.materialId);

    return material?.measurement || '';
});

</script>

<style scoped lang="scss"></style>
