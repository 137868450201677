<template>
    <div
        class="border border-2 rounded-3 mx-2 my-2 d-flex justify-content-center align-items-center cursor-pointer position-relative tile"
        :class="data.bg"
        style="border-color: #333 !important; width:60px; height: 100px;"
        @click.stop="listHandler">
        <div class="fw-bold">
            {{ data.percent }}%
        </div>
        <div
            v-if="user.isAdmin || user.isTechnologist || user.isSales || user.isSuperAdmin"
            class="tile__add fw-bold"
            @click.stop="addHandler">
            +
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { userStore } from "@/store/userStore";

export default defineComponent({
    name: "TileComponent",
    props: [ 'data' ],
    setup(props, { emit }) {
	    const { user } = userStore();

	    function addHandler() {
		    emit('on-add-section', {
			    column: props.data.column,
			    row: props.data.row,
			    monthInd: props.data.monthInd,
		    });
	    }

	    function listHandler() {
		    emit('on-list-sections', {
			    column: props.data.column,
			    row: props.data.row,
			    monthInd: props.data.monthInd,
		    });
	    }

        return {
	        user,
            addHandler,
	        listHandler,
        };
    },
});
</script>

<style scoped lang="scss">

.tile:hover .tile__add {
	display: block;
}
.tile__add {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 20px;
	text-align:center;
	color: #fff;
	background: rgba(0,0,0,.5);
	display: none;
}
</style>