<template>
    <nav id="sidebar" class="sidebar">
        <simple-bar class="sidebar-content">
            <router-link class="sidebar-brand p-3 m-0 d-flex align-items-center ms-2" to="/">
                <span class="sidebar-brand-text align-middle">
                    <!--                    <logo-white height="32px" />-->
                </span>
                <svg
                    height="24px"
                    viewBox="0 0 32 32"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                        <g>
                            <path
                                d="m31,27.5h-30c-0.553,0-1,0.447-1,1 0,0.553 0.447,1 1,1h30c0.553,0 1-0.447 1-1 0-0.553-0.447-1-1-1zm-21.615-16.769c-0.229,0.183-0.385,0.453-0.385,0.769 0,0.553 0.447,1 1,1h9c0.553,0 1-0.447 1-1v-4c0-0.553-0.447-1-1-1h-4c-0.236,0-0.444,0.094-0.615,0.231l-.01-.012-5,4 .01,.012zm5.966-2.231h2.649v2h-5.149l2.5-2zm15.649-6h-17c-0.89,0-1.681,0.395-2.231,1.011l-9.799,9.035c-1.19,0.914-1.97,2.337-1.97,3.954 0,2.762 2.238,5 5,5h2.461l-2.148,2.148c-0.226,0.226-0.322,0.525-0.307,0.821 0,0.012-0.006,0.02-0.006,0.031 0,0.553 0.447,1 1,1h6c0.014,0 0.025-0.008 0.039-0.008 0.291,0.011 0.586-0.088 0.809-0.31l3.682-3.683h14.47c0.553,0 1-0.447 1-1v-17c0-0.552-0.447-0.999-1-0.999zm-19.539,21h-2.932l2-2h2.932l-2,2zm18.539-4h-25c-1.656,0-3-1.343-3-3 0-0.919 0.422-1.73 1.072-2.28l-.041-.001 10.195-9.328c0.184-0.233 0.456-0.391 0.774-0.391h16v15z"
                                fill="white" />
                        </g>
                    </g>
                </svg>
                <span class="ps-3">{{ depoName }}</span>
                <!--                <logo-white-min class="sidebar-brand-icon align-middle" height="32px" />-->
            </router-link>
            <ul class="sidebar-nav">
                <template v-for="(group, groupInd) in menu" :key="'group' + groupInd">
                    <li v-if="group.groupName !== null && group.isShow" class="sidebar-header">
                        {{ group.groupName }}
                    </li>
                    <template v-for="(item, itemInd) in group.children" :key="'item' + itemInd">
                        <li class="sidebar-item" :class="{ active: isActive(item) }">
                            <div
                                v-if="item.hasOwnProperty('children') && item.isShow"
                                class="sidebar-link no-select"
                                :class="{ collapsed: !item.open || isActive(item) }"
                                data-bs-toggle="collapse"
                                @click="item.open = !item.open">
                                <!-- <component :is="item.icon" /> -->
                                <span class="align-middle">{{ item.title }}</span>
                            </div>
                            <router-link
                                v-if="!item.hasOwnProperty('children') && item.isShow"
                                class="sidebar-link no-select"
                                :class="{ active: isActive(item) }"
                                :to="{ name: item.routeName }">
                                <!-- <component :is="item.icon" /> -->
                                <span class="align-middle">{{ item.title }}</span>
                            </router-link>
                            <ul
                                v-if="item.hasOwnProperty('children') && (item.open || isActive(item))"
                                class="sidebar-dropdown list-unstyled">
                                <template v-for="(sub, subInd) in item.children" :key="'sub' + subInd">
                                    <li v-if="sub.isShow" class="sidebar-item" :class="{ active: isActive2(sub) }">
                                        <div
                                            v-if="sub.hasOwnProperty('children') && sub.isShow"
                                            class="sidebar-link no-select"
                                            @click="sub.open = !sub.open">
                                            {{ sub.title }}
                                        </div>
                                        <router-link
                                            v-else
                                            class="sidebar-link no-select"
                                            :to="{ name: sub.routeName }">
                                            <span v-html="sub.title"></span>&nbsp;
                                            <span
                                                v-if="
                                                    sub.routeName === 'SupplierSelectionIndex' &&
                                                    (user.isAdmin || user.isSuperAdmin) &&
                                                    state.sentForApprovalProtocolsCount
                                                "
                                                class="badge bg-warning text-dark">
                                                {{ state.sentForApprovalProtocolsCount }}
                                            </span>
                                            <span
                                                v-if="
                                                    sub.routeName === 'InvoiceIndex' && +state.invoicesToBeCapitalized
                                                "
                                                class="badge bg-warning text-dark">
                                                {{ state.invoicesToBeCapitalized }}
                                            </span>
                                            <span
                                                v-if="
                                                    sub.routeName === 'PurchaseRequestsIndex' &&
                                                    +state.materialsToBePurchased
                                                "
                                                class="badge bg-warning text-dark">
                                                {{ state.materialsToBePurchased }}
                                            </span>
                                            <span
                                                v-if="
                                                    sub.routeName === 'MaterialsToBeReceivedIndex' &&
                                                    +state.materialsToBeReceived
                                                "
                                                class="badge bg-warning text-dark">
                                                {{ state.materialsToBeReceived }}
                                            </span>
                                            <span
                                                v-if="
                                                    sub.routeName === 'RequestsPaymentsIndex' &&
                                                    +state.protocolsToBePaid
                                                "
                                                class="badge bg-warning text-dark">
                                                {{ state.protocolsToBePaid }}
                                            </span>
                                        </router-link>
                                        <ul
                                            v-if="sub.hasOwnProperty('children') && (sub.open || isActive(sub))"
                                            class="sidebar-dropdown list-unstyled">
                                            <template v-for="(sub2, sub2Ind) in sub.children" :key="'sub2' + sub2Ind">
                                                <li
                                                    v-if="sub2.isShow"
                                                    class="sidebar-item"
                                                    :class="{ active: isActive2(sub2) }">
                                                    <router-link
                                                        class="sidebar-link no-select"
                                                        :to="{ name: sub2.routeName }">
                                                        <span v-html="sub2.title"></span>
                                                    </router-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </template>
            </ul>
        </simple-bar>
    </nav>
</template>

<script lang="ts" async setup>
import { ref, computed, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { userStore } from '@/store/userStore';
import api from '@/api';
import { socketOn } from '@/composables/useSocket';
import { notify } from '@kyvg/vue3-notification';

const depoId = parseInt(localStorage.getItem('depoId') as string);
const audio = new Audio('notify.mp3');

type MenuItemType = {
    groupName: null;
    isShow: boolean;
    children: Array<MenuSubItemType>;
    open?: boolean;
};

type MenuSubItemType = {
    title?: string;
    isShow: boolean;
    routeName?: string;
    children?: Array<MenuSubItemType>;
    subRoutes?: Array<string>;
    open?: boolean;
};

const route = useRoute();
const { user } = userStore();
const depoName = user.depots.find((i) => i.depoId === depoId).depoName;
const state = reactive({
    sentForApprovalProtocolsCount: 0,
    materialsToBePurchased: 0,
    materialsToBeReceived: 0,
    invoicesToBeCapitalized: 0,
    protocolsToBePaid: 0,
});

function getCountOfSentForApproval() {
    return api.supplierSelection.getCountOfSentForApproval().then((res) => {
        state.sentForApprovalProtocolsCount = res.data?.count || 0;
    });
}

function getNotifications() {
    return api.notification.get().then((res) => {
        state.materialsToBePurchased = res.data?.materialsToBePurchased || 0;
        state.materialsToBeReceived = res.data?.materialsToBeReceived || 0;
        state.invoicesToBeCapitalized = res.data?.invoicesToBeCapitalized || 0;
        state.protocolsToBePaid = res.data?.protocolsToBePaid || 0;
    });
}

onMounted(() => {
    if (user.isAdmin || user.isSuperAdmin) {
        getCountOfSentForApproval();
    }
    getNotifications();
});

if (user.isAdmin || user.isSuperAdmin) {
    socketOn('protocol_sent_for_approval', (r) => {
        if (Number(r.depoId) !== depoId) return;
        getCountOfSentForApproval();
        audio.volume = 0.7;
        const promise = audio?.play();
        if (promise !== undefined) {
            promise.then((_) => {}).catch(console.error);
        }
        notify({ title: 'Внимание', text: 'Протокол выбора поставщика отправлен на согласование', type: 'warn' });
    });
}

socketOn('count_changed', (r) => {
    if (Number(r.depoId) !== depoId) return;
    getNotifications();
});

const menu = ref<Array<MenuItemType>>([
    {
        groupName: null,
        isShow: true, // user.isLoggedIn,
        children: [
            {
                title: 'Депо',
                isShow: true,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                routeName: 'LocationIndex',
                subRoutes: ['LocationShow'],
            },
            {
                title: 'Планирование',
                isShow:
                    user.isSuperAdmin ||
                    user.isAdmin ||
                    user.isTechnologist ||
                    user.isSales ||
                    user.isZakup ||
                    user.isHeadOfDepot ||
                    user.isCEO,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                subRoutes: ['LoadingDitchesIndex', 'ProjectIndex'],
                children: [
                    {
                        title: 'Проекты КР',
                        isShow: [1, 7, 8, 9, 11, 12, 8].includes(user.roleId),
                        routeName: 'ProjectIndex',
                    },
                    {
                        title: 'Загрузка канав и Нормы длительности ТО',
                        isShow: true,
                        routeName: 'LoadingDitchesIndex',
                    },
                ],
            },
            {
                title: 'Работы ТОиР',
                isShow:
                    user.isSuperAdmin ||
                    user.isAdmin ||
                    user.isMaster ||
                    user.isTechnologist ||
                    user.isHeadOfDepot ||
                    user.isKadry ||
                    user.isAccountant,
                subRoutes: ['SectionJobSummaryIndex', 'OperationalAccountingView', 'PricesView', 'Await1'],
                children: [
                    {
                        title: 'Табелирование',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isKadry ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'EmployeeReportCardViewIndex',
                    },
                    {
                        title: 'Субподряд',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isKadry ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'Await1',
                    },
                    {
                        title: 'Расценки',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isMaster,
                        routeName: 'EmployeeTariffIndex',
                    },
                    {
                        title: 'Отчет',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isMaster ||
                            user.isTechnologist ||
                            user.isHeadOfDepot,
                        routeName: 'OperationalAccountingView',
                    },
                ],
            },
            {
                title: 'Материалы',
                isShow:
                    user.isSuperAdmin ||
                    user.isAdmin ||
                    user.isCentrsklad ||
                    user.isTechnologist ||
                    user.isZakup ||
                    user.isCTO ||
                    user.isAccountant ||
                    user.isHeadOfDepot ||
                    user.isInstr,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                subRoutes: [
                    'MaterialRequirementIndex',
                    'MaterialRequirementNewShow',
                    'MaterialRequirementNewIndex',
                    'PurchaseRequestsIndex',
                    'MaterialsToBeReceivedIndex',
                    'BookingMaterialsListIndex',
                    'InvoiceIndex',
                    'StockReceiptIndex',
                    'StockReleaseIndex',
                    'InventoryResiduesIndex',
                    'StockTakingIndex',
                ],
                children: [
                    {
                        title: 'Потребность ТМЦ',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isCEO ||
                            user.isCentrsklad ||
                            user.isTechnologist ||
                            user.isZakup ||
                            user.isHeadOfDepot,
                        routeName: 'MaterialRequirementNewIndex',
                        subRoutes: ['MaterialRequirementNewShow'],
                    },
                    {
                        title: 'Заявки на закуп ТМЦ',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isCEO ||
                            user.isCentrsklad ||
                            user.isTechnologist ||
                            user.isZakup ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'PurchaseRequestsIndex',
                    },
                    {
                        title: 'Выбор поставщика',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isZakup ||
                            user.isCTO ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'SupplierSelectionIndex',
                    },
                    {
                        title: 'Лист ожидания',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isZakup ||
                            user.isCTO ||
                            user.isCentrsklad ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'MaterialsToBeReceivedIndex',
                    },
                    {
                        title: 'Лист бронирования',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isZakup ||
                            user.isCTO ||
                            user.isCentrsklad ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'BookingMaterialsListIndex',
                    },
                    {
                        title: 'Накладные ТМЦ',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isZakup ||
                            user.isCTO ||
                            user.isCentrsklad ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'InvoiceIndex',
                    },
                    {
                        title: 'Центральный склад',
                        subRoutes: [
                            'StockReceiptIndex',
                            'StockReleaseIndex',
                            'InventoryResiduesIndex',
                            'StockTakingIndex',
                        ],
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isInstr ||
                            user.isCentrsklad ||
                            user.isZakup ||
                            user.isAccountant ||
                            user.isHeadOfDepot,
                        routeName: 'StockReceiptIndex',
                    },
                ],
            },
            {
                title: 'Финансы',
                isShow: user.isSuperAdmin || user.isAdmin || user.isZakup || user.isCTO || user.isAccountant,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                subRoutes: ['Await2', 'RequestsPaymentsIndex', 'PaymentRegistersIndex'],
                children: [
                    {
                        title: 'Заявки на оплату ТМЦ',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isAccountant,
                        routeName: 'RequestsPaymentsIndex',
                    },
                    {
                        title: 'Заявки на оплату услуг',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isAccountant,
                        routeName: 'Await2',
                    },
                    {
                        title: 'Реестры оплат',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isAccountant || user.isZakup || user.isCTO,
                        routeName: 'PaymentRegistersIndex',
                    },
                ],
            },
            {
                title: 'Инструментальный цех',
                isShow: user.isSuperAdmin || user.isAdmin || user.isInstr || user.isCentrsklad || user.isZakup,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                routeName: 'ToolsEmployeesReleaseIndex',
                subRoutes: ['ToolsEmployeesReceiptIndex', 'ToolsEmployeesReleaseIndex', 'Await3'],
            },
            {
                title: 'Завершенные проекты',
                isShow: user.isSuperAdmin || user.isAdmin || user.isCEO || user.isCTO,
                routeName: 'CompletedProjectsIndex',
            },
            {
                title: 'Документы',
                isShow: [1, 2, 7, 8, 9, 12].includes(user.roleId),
                routeName: 'DocumentsIndex',
            },
            {
                title: 'Справочники и нормы',
                isShow:
                    user.isSuperAdmin ||
                    user.isAdmin ||
                    user.isInstr ||
                    user.isSales ||
                    user.isZakup ||
                    user.isAccountant ||
                    user.isTechnologist ||
                    user.isKadry ||
                    user.isHeadOfDepot,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                subRoutes: [
                    'LocomotiveIndex',
                    'SupplierIndex',
                    'UserIndex',
                    'DepartmentIndex',
                    'MaterialsIndex',
                    'ToolIndex',
                    'Await6',
                    'Await7',
                    'MatNormsIndex',
                    'EmployeeIndex',
                ],
                children: [
                    {
                        title: 'Контрагенты',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isInstr ||
                            user.isSales ||
                            user.isZakup ||
                            user.isAccountant ||
                            user.isTechnologist,
                        routeName: 'SupplierIndex',
                    },
                    {
                        title: 'Парк техники',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isInstr ||
                            user.isSales ||
                            user.isZakup ||
                            user.isTechnologist,
                        routeName: 'LocomotiveIndex',
                    },
                    {
                        title: 'Пользователи',
                        isShow: user.isSuperAdmin,
                        routeName: 'UserIndex',
                    },
                    {
                        title: 'Подразделения',
                        isShow: user.isSuperAdmin,
                        routeName: 'DepartmentIndex',
                    },
                    {
                        title: 'Сотрудники',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isKadry ||
                            user.isHeadOfDepot ||
                            user.isAccountant,
                        routeName: 'EmployeeIndex',
                    },
                    {
                        title: 'Материалы',
                        isShow:
                            user.isSuperAdmin ||
                            user.isAdmin ||
                            user.isInstr ||
                            user.isSales ||
                            user.isZakup ||
                            user.isAccountant ||
                            user.isTechnologist,
                        routeName: 'MaterialsIndex',
                    },
                    {
                        title: 'Услуги',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isInstr || user.isSales || user.isZakup,
                        routeName: 'Await6',
                    },
                    {
                        title: 'Инструменты и инвентарь',
                        isShow: user.isSuperAdmin || user.isAdmin || user.isInstr || user.isSales || user.isZakup,
                        routeName: 'ToolIndex',
                    },
                    // {
                    //     title: 'Нормы по материалам',
                    //     isShow: user.isSuperAdmin || user.isAdmin || user.isInstr || user.isSales,
                    //     routeName: 'MatNormsIndex',
                    // },
                ],
            },
            {
                title: 'Отчеты',
                isShow:
                    user.isSuperAdmin ||
                    user.isAdmin ||
                    user.isKadry ||
                    user.isInstr ||
                    user.isCentrsklad ||
                    user.isSales ||
                    user.isCEO ||
                    user.isCTO ||
                    user.isAccountant,
                // icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                routeName: 'ReportIndex',
            },
        ],
    },
]);

const isActive = computed(() => {
    return (item: any) => {
        if (item.hasOwnProperty('routeName') && route.name == item.routeName) return true;
        if (item.hasOwnProperty('subRoutes') && item.subRoutes.includes(route.name)) return true;
        if (item.hasOwnProperty('children') && item.children.map((i: any) => i.routeName).includes(route.name))
            return true;

        return false;
    };
});

const isActive2 = computed(() => {
    return (item: any) => {
        if (item.hasOwnProperty('routeName') && route.name == item.routeName) return true;
        if (item.hasOwnProperty('subRoutes') && item.subRoutes.includes(route.name)) return true;

        return false;
    };
});
</script>

<style scoped lang="scss"></style>
